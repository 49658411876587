<template>
  <div>
    <ShiftsTable actionType="accept" />
  </div>
</template>

<script>
import ShiftsTable from "@employer/ShiftsApplicant";

export default {
  components: {
    ShiftsTable,
  },
};
</script>
